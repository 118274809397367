import axios from 'axios';

let prefix = "/eventArtist"

const EventArtistApi = {
	
	getEventArtistList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventArtist(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventArtist(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
}

export default EventArtistApi;