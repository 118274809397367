import axios from 'axios';

let prefix = "/eventTicketStock"

const EventTicketTypeApi = {
	
	listEventTicketStock(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	updateEventTicketStock(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventTicketTypeApi;