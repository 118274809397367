import axios from 'axios';

let prefix = "/event"

const EventDateApi = {
	getEventDateList(payload){
		return window.Vue.axios.get( prefix + "/list-date", {params: payload})
	},
	deleteEventDate(payload){
		return window.Vue.axios.delete( prefix + "/delete-date", {params: payload})
	},
	createEventDate(payload){
		return window.Vue.axios.post( prefix + "/create-date", payload)
	},
	updateEventDate(payload){
		return window.Vue.axios.post( prefix + "/update-date", payload)
	},
}

export default EventDateApi;