import axios from 'axios';

let prefix = "/eventOrganizer"

const EventOrganizerApi = {
	
	getEventOrganizerList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventOrganizer(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventOrganizer(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
}

export default EventOrganizerApi;