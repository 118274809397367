import axios from 'axios';

let prefix = "/auth"
let prefix_user = "/user"

const AuthApi = {
	
	changePassword(payload){
		return window.Vue.axios.post( prefix_user + "/updatePassword", payload)
	},
	updateUserProfile(payload){
		return window.Vue.axios.post( prefix_user + "/update", payload)
	},
	forgotPassword(payload){
		return window.Vue.axios.post( prefix_user + "/sendMailResetPassword", payload)
	},
	resetPassword(payload){
		return window.Vue.axios.post( prefix_user + "/resetUpdatePassword", payload)
	},
	verifyEmail(payload){
		return window.Vue.axios.post( prefix_user + "/verifyEmailToken", payload)
	},	
	getUserInfo(id, payload){
		return window.Vue.axios.get( prefix_user + "/get/"+id, {params: payload})
	},
	getUserList(payload){
		return window.Vue.axios.get( prefix_user + "/list", {params: payload})
	},
	exportUserList(payload){
		return window.Vue.axios.get( prefix + "/list-export", {params: payload})
	},
	createUser(payload){
		return window.Vue.axios.post( prefix_user + "/adminCreate", payload)
	},
	updateUser(payload){
		return window.Vue.axios.post( prefix_user + "/adminUpdate", payload)
	},
	archiveUser(payload){
		return window.Vue.axios.delete( prefix_user + "/delete", {params: payload})
	},

	getUserRefs(payload){
		return window.Vue.axios.get( prefix + "/refs", {params: payload})
	},

	getGenderTypes(payload){
		return window.Vue.axios.get( prefix_user + "/getGenderTypes", {params: payload})
	},

	updateUserPassword(payload){
		return window.Vue.axios.post( prefix_user + "/adminUpdatePassword", payload)
	},
	checkUserHasPassword(payload){
		return window.Vue.axios.get( prefix + "/check-password", {params: payload})
	},
	sendAccountActivation(payload){
		return window.Vue.axios.post( prefix_user + "/sendActivationEmail", payload)
	},
	verifyUserVaccination(payload){
		return window.Vue.axios.post( prefix_user + "/verify-vaccination", payload)
	},
	getVaccinationStatusRefs(payload){
		return window.Vue.axios.get( prefix_user + "/vaccination-status", {params: payload})
	},

}

export default AuthApi;