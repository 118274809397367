let prefix = "/advertisementBanner"

const AdvertisementApi = {
	
	getAdvertisementList(payload){
		return window.Vue.axios.get( prefix + "/list-admin", {params: payload})
	},
	archiveAdvertisement(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createAdvertisement(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateAdvertisement(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setAdvertisementImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearAdvertisementImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-banner", {params: payload})
	},
	getActionTypes(payload){
		return window.Vue.axios.get( prefix + "/get-action-types", {params: payload})
	},
}

export default AdvertisementApi;