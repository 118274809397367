import axios from 'axios'



const createAxios = (baseURL) => {
	const newInstance = axios.create({ baseURL: baseURL + "/api/" });
 
	// newInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	newInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
	newInstance.defaults.headers.common['openKey'] = 'web';
	newInstance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('config-X')

	
	// newInstance.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
	// newInstance.defaults.withCredentials = false

	newInstance.interceptors.response.use( (response) => {
		// Return a successful response back to the calling service
		return response;
	}, (error) => {
		console.log(error,"errr")
		if (error.response.status == 429)
		{
			window.Vue.prototype['$toast'].warning('Too Many Requests, Please Try Again Later.')
		}
		else if (error.response.status === 401)
		{
			// Logout user if token refresh didn't work or user is disabled
			window.localStorage.clear()
			if(window.Vue.router.history.current.path != '/login')
			{
				window.Vue.router.push('/login')
				window.Vue.prototype['$toast'].warning('Session Expired. Please Login Again.')
			}
				

			return new Promise((resolve, reject) => {
				reject(error.response);
			});
		}

		// Return any error which is not due to authentication back to the calling service
		return new Promise((resolve, reject) => {
			reject(error.response);
		});

	});
 
	return newInstance;
}

export default createAxios;


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
