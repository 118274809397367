import axios from 'axios';

let prefix = "/item-stock"

const ItemStockApi = {
	createItemStock(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	getItemStockList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
}

export default ItemStockApi;