import axios from 'axios';

let prefix = "/report"

const ReportApi = {
	
	getReportList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getEventDate(payload){
		return window.Vue.axios.get( prefix + "/event-date", {params: payload})
	},
	generateReportDailySales(payload){
		return window.Vue.axios.get( prefix + "/daily-sales", {params: payload})
	},
	generateEventManifest(payload){
		return window.Vue.axios.get( prefix + "/event-manifest", {params: payload})
	},
	generateDailySalesByCategory(payload){
		return window.Vue.axios.get( prefix + "/daily-sales-category", {params: payload})
	},
	generateNetGrandTotalReport(payload){
		return window.Vue.axios.get( prefix + "/net-grand-total", {params: payload})
	},
	generateReportbyZoneSection(payload){
		return window.Vue.axios.get( prefix + "/zone-or-section", {params: payload})
	},
}

export default ReportApi;