let prefix = "/eventSponsor"

const EventSponsorApi = {
	
	getEventSponsorList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventSponsor(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventSponsor(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventSponsor(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setEventSponsorImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearEventSponsorImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-banner", {params: payload})
	},
}

export default EventSponsorApi;