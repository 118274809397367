import axios from 'axios';

let prefix = "/product-category"

const ProductCategoryApi = {
	
	getProductCategoryList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	createProductCategory(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	updateProductCategory(payload){
		return window.Vue.axiosLaravel.put( prefix + "/update", payload)
	},
	archiveProductCategory(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
	/* getProductRefs(payload){
		return window.Vue.axios.get( prefix + "/refs", {params: payload})
	}, */
}

export default ProductCategoryApi;