import axios from 'axios';

let prefix = "/artist"

const ArtistApi = {
	
	getArtistList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getArtist(payload){
		return window.Vue.axios.get( prefix + "/get", {params: payload})
	},
	archiveArtist(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createArtist(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateArtist(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setArtistImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearArtistImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default ArtistApi;