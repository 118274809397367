import axios from 'axios';

let prefix = "/organizer"

const OrganizerApi = {
	
	getOrganizerList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveOrganizer(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createOrganizer(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateOrganizer(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setOrganizerImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearOrganizerImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default OrganizerApi;