import axios from 'axios';

let prefix = "/eventDoor"

const EventDoorApi = {
	
	getEventDoorInfo(payload){
		return window.Vue.axios.get( prefix + "/get", {params: payload})
	},
	getEventDoorList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventDoor(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventDoor(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventDoor(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventDoorApi;