import axios from 'axios';

let prefix = "/broadcast"

const BroadcastApi = {
	
	getBroadcastList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	createBroadcastMessage(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	archiveBroadcastMessage(payload){
		return window.Vue.axios.delete( prefix + "/archive", {params: payload})
	},

}

export default BroadcastApi;