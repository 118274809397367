import axios from 'axios';

let prefix = "/artistMember"

const ArtistMemberApi = {
	
	getArtistMemberList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getArtistMember(payload){
		return window.Vue.axios.get( prefix + "/get", {params: payload})
	},
	archiveArtistMember(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createArtistMember(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateArtistMember(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setArtistMemberImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearArtistMemberImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default ArtistMemberApi;