import axios from 'axios';

let prefix = "/event"

const EventApi = {
	
	getEventList(payload){
		return window.Vue.axios.get( prefix + "/list-admin", {params: payload})
	},
	getEventInfo(id, payload){
		return window.Vue.axios.get( prefix + "/get/" + id, {params: payload})
	},
	getPurchaseTypes(payload){
		return window.Vue.axios.get( prefix + "/list-type", {params: payload})
	},
	archiveEvent(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEvent(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventTnc(payload){
		return window.Vue.axios.post( prefix + "/update-tnc", payload)
	},
	updateEvent(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setEventImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	getEventImageList(payload){
		return window.Vue.axios.get( prefix + "/list-image", {params: payload})
	},
	archiveEventImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-image", {params: payload})
	},
	setEventSeatMap(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload-seat-map", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	deleteEventSeatMap(payload){
		return window.Vue.axios.delete( prefix + "/delete-seat-map", {params: payload})
	},
	setEventETicket(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload-e-ticket", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	deleteEventETicket(payload){
		return window.Vue.axios.delete( prefix + "/delete-e-ticket", {params: payload})
	},
	deleteTicketBanner(payload){
		return window.Vue.axios.delete( prefix + "/delete-ticket-banner", {params: payload})
	},
	getETicketPreview(payload){
		return window.Vue.axios.get( prefix + "/preview-e-ticket", {params: payload})
	},
	generateDailySalesReport(payload){
		return window.Vue.axios.get( prefix + "/report/daily-sales", {params: payload})
	},
	setEventTicketBanner(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload-ticket-banner", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},

	//EVENT TICKET
	getEventTicketInfo(payload){
		return window.Vue.axios.get( prefix + "/get-ticket", {params: payload})
	},
	getEventTicketList(payload){
		return window.Vue.axios.get( prefix + "/list-ticket", {params: payload})
	},
	createEventTicket(payload){
		return window.Vue.axios.post( prefix + "/create-ticket", payload)
	},
	updateEventTicket(payload){
		return window.Vue.axios.post( prefix + "/update-ticket", payload)
	},
	archiveEventTicket(payload){
		return window.Vue.axios.delete( prefix + "/delete-ticket", {params: payload})
	},
}

export default EventApi;