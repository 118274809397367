import axios from 'axios';

let prefix = "/category"

const CategoryApi = {
	
	getCategoryInfo(id, payload){
		return window.Vue.axios.get( prefix + "/info/"+id, {params: payload})
	},
	getCategoryAll(){
		return window.Vue.axios.get( prefix + "/all")
	},
	getCategoryList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	createCategory(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateCategory(payload){
		return window.Vue.axios.put( prefix + "/update", payload)
	},
	archiveCategory(payload){
		return window.Vue.axios.delete( prefix + "/archive", {params: payload})
	},
	/* getCategoryRefs(payload){
		return window.Vue.axios.get( prefix + "/refs", {params: payload})
	}, */
}

export default CategoryApi;