import axios from 'axios';

let prefix = "/eventSection"

const EventSectionApi = {
	
	getEventSectionInfo(payload){
		return window.Vue.axios.get( prefix + "/get", {params: payload})
	},
	getEventSectionList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventSection(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventSection(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventSection(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventSectionApi;