const geoZoneApi = {
	getGeoZoneList(payload){
		return window.Vue.axiosLaravel.get("/geo-zone/get", {params: payload})
	},
	createGeoZone(payload){
		return window.Vue.axiosLaravel.post("/geo-zone/create", payload)
	},
	updateGeoZone(payload){
		return window.Vue.axiosLaravel.post("/geo-zone/update", payload)
	},
	archiveGeoZone(payload){
		return window.Vue.axiosLaravel.post("/geo-zone/delete", payload)
	},
}

export default geoZoneApi;