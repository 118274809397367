let prefix = "/courier"

const courierApi = {
	
	getCourierList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/get", {params: payload})
	},
	createCourier(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload)
	},
	updateCourier(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update", payload)
	},
	archiveCourier(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update", payload)
	},
}


export default courierApi;