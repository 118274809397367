import axios from 'axios';

let prefix = "/stock-type"

const StockTypeApi = {
	getStockTypeRefs(){
		return window.Vue.axiosLaravel.get( prefix + "/refs")
	},
	getStockTypeIndex(){
		return window.Vue.axiosLaravel.get( prefix + "/index")
	},
}

export default StockTypeApi;