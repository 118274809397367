import axios from 'axios';

let prefix = "/states"

const StateApi = {
	
	getStateList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getStateListFilter(payload){
		return window.Vue.axios.get( prefix + "/list-filter", {params: payload})
	},

}

export default StateApi;